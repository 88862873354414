import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Badge,
  FormControl,
  MenuItem,
  Table as TableMui,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import {faEdit, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import DialogActions from '@material-ui/core/DialogActions';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import Checkbox from '@material-ui/core/Checkbox';
import validator from 'validator/es';
import {
  faCheckSquare, faEye, faSquare,
} from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {displayError, displaySuccess} from '../../ui-components/displayMsg';
import SelectMultipleSearch from '../Inputs/SelectMultipleSearch';
import {
  deleteFetch, getFetch, postFetch, putFetch,
} from '../../ApiBackend';
import {personifyOperator} from '../../actions/operatorAction';

import RedTextField from '../../core/components/inputs/RedTextField';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import RedButton from '../../core/components/buttons/RedButton';
import {
  SearchableInput, StyledTableCell, StyledTableRow,
} from '../../Components/Table';
import BoxInfo from '../../Components/BoxInfo';
import {setPath} from '../../actions/pathAction';
import {setSelectedService} from '../../actions/servicesAction';
import {TablePaginationActions} from './UsersManagement';


/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function ServicesManagement(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Services Management'));
  }, [dispatch]);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (<Grid
    container
    className={classes.grid}
    style={{
      overflow: 'overlay',
    }}
  >
    <Grid
      item
      xs={12}
      style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
    >
      <ServicesPage {...props} />
    </Grid>
  </Grid>);
}

const useStyles = makeStyles(() => ({
  width50: {
    width: '50%',
  }, noWrap: {
    whiteSpace: 'nowrap',
  },
}));

/**
 *
 * @param{number} forceRefresh
 * @return {(*[]|boolean)[]}
 */
export function useKeycloakServices(forceRefresh = 0) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const services = useSelector((state) => state.services);
  const viewServices = services.map((s) => s.hash);
  useEffect(() => {
    setLoading(true);
    setGroups([]);
    getFetch('service').then((data) => {
      if (data[0]) {
        setGroups(data[0].subGroups);
        setLoading(false);
      }
    });
  }, [forceRefresh]);
  return [groups.filter((g) => viewServices.includes(g.name)), loading];
}

/**
 *
 * @param{object} props
 * @return {JSX.Element}
 * @constructor
 */
function ServicesPage(props) {
  const servs = useSelector((state) => state.services);
  const [updating, setUpdating] = useState(false);
  const [from, setFrom] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [, setPage] = useState(Math.floor(from / rowsPerPage));
  const [allServices, setAllServices] = useState([]);
  const [services, setServices] = useState([]);
  const [personify, setPersonify] = useState([]);

  const [count, setCount] = useState(0);

  const [searchServicename, setSearchServicename] = useState('');
  const [searchHash, setSearchHash] = useState('');

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [superGroupId, setSuperGroupId] = useState('');

  const classes = useStyles();

  useEffect(() => {
    getFetch('service').then((data) => {
      if (data[0]) {
        setSuperGroupId(data[0].id);
        setAllServices(data[0].subGroups);
        setCount(data[0].subGroups.length);
      }
    });
  }, [refresh]);

  useEffect(() => {
    setFrom(0);
  }, [searchHash, searchServicename]);

  useEffect(() => {
    let filtered = allServices;
    if (searchServicename) {
      filtered = filtered.filter((s) => {
        const {name} = JSON.parse(s.attributes.group_info);
        const sName = (name || '').toLowerCase();
        return sName.indexOf(searchServicename.toLowerCase()) !== -1;
      });
    }
    if (searchHash) {
      filtered = filtered.filter((s) => s.name.indexOf(searchHash.toLowerCase()) !== -1);
    }
    setServices(filtered.slice(from, from + rowsPerPage));
  }, [from, searchServicename, searchHash, rowsPerPage, allServices, refresh]);

  const refreshing = () => setRefresh((o) => o + 1);

  useEffect(() => {
    if (deleting) {
      const deletingService = deleting.name || deleting.hash;
      deleteFetch('service/' + deleting.id).then((_) => {
        displaySuccess('Delete service', `${deletingService} was deleted.`);
        setDeleting(false);
        refreshing();
      }).catch((_) => {
        displayError(`Error in Delete service`, `${deletingService} was not deleted.`);
        setDeleting(false);
      });
    }
  }, [deleting]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, services.length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFrom(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlePersonify = (id, name) => {
    return () => {
      if (personify[id]) {
        setPersonify((p) => {
          const newP = {...p};
          delete newP[id];
          return newP;
        });
      } else {
        setPersonify((p) => ({...p, [id]: name}));
      }
    };
  };

  const dispatch = useDispatch();
  const servicePersonify = (hashs) => {
    return () => {
      dispatch(setSelectedService('all'));
      dispatch(personifyOperator(hashs));
      props.history.push('/');
    };
  };

  return (<BoxInfo
    title={<div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <b>Services</b>
      <RedIconButton
        TooltipProps={{title: 'Add new service'}}
        onClick={() => setUpdating(true)}
      >
        <FontAwesomeIcon icon={faPlus}/>
      </RedIconButton>
      <UpsertService
        handleClose={() => setUpdating(false)}
        open={!!updating}
        service={updating}
        super_group_id={superGroupId}
        onCreation={refreshing}
      />
    </div>}
  >
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell/>
            <StyledTableCell className={classes.width50}>
              <SearchableInput
                name={<b>Service Name</b>}
                onKeyPress={({
                  target: {value},
                  key,
                }) => key === 'Enter' && setSearchServicename(value)}
              />
            </StyledTableCell>
            <StyledTableCell className={classes.width50}>
              <SearchableInput
                name={<b>Service Hash</b>}
                onKeyPress={({
                  target: {value},
                  key,
                }) => key === 'Enter' && setSearchHash(value)}
              />
            </StyledTableCell>
            <StyledTableCell className={classes.noWrap}>
              <b>Internal Plugin?</b>
            </StyledTableCell>
            <StyledTableCell className={classes.noWrap}>
              <b>Expired?</b>
            </StyledTableCell>
            <StyledTableCell className={classes.noWrap}>
              <b>Max Qps?</b>
            </StyledTableCell>
            <StyledTableCell colSpan={2}>
              <b>Actions</b>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => {
            const {
              internal,
              expire,
              name,
              mqps,
              hash,
            } = JSON.parse(service?.attributes?.group_info);
            const selectableService = servs.some((s) => s.hash === hash);
            return (<StyledTableRow key={service.id}>
              <StyledTableCell>
                <RedIconButton
                  alwaysTooltip={!selectableService}
                  disabled={!selectableService}
                  TooltipProps={(!selectableService && {
                    title: 'It is not in your active services.',
                  }) || undefined}
                  size="small"
                  onClick={handlePersonify(hash, name)}
                >
                  <FontAwesomeIcon
                    icon={(!personify[hash] && faSquare) || faCheckSquare}
                  />
                </RedIconButton>
              </StyledTableCell>
              <StyledTableCell>{name}</StyledTableCell>
              <StyledTableCell>{service.name}</StyledTableCell>
              <StyledTableCell>
                {internal ? <FontAwesomeIcon icon={faCheck}/> : '-'}
              </StyledTableCell>
              <StyledTableCell className={classes.noWrap}>
                <Expired expire={expire}/>
              </StyledTableCell>
              <StyledTableCell style={{textAlign: 'right'}}>
                {mqps}
              </StyledTableCell>
              <StyledTableCell>
                <RedIconButton
                  size="small"
                  TooltipProps={{title: 'Edit Service'}}
                  onClick={() => setUpdating(service)}
                >
                  <FontAwesomeIcon icon={faEdit}/>
                </RedIconButton>
              </StyledTableCell>
              <StyledTableCell>
                <RedIconButton
                  size="small"
                  TooltipProps={{title: 'Delete Service'}}
                  onClick={() => setConfirmDelete({
                    internal, expire, name, mqps, hash, id: service.id,
                  })}
                >
                  <FontAwesomeIcon icon={faTrash}/>
                </RedIconButton>
              </StyledTableCell>
            </StyledTableRow>);
          })}
          {emptyRows > 0 && (<StyledTableRow style={{height: 34 * emptyRows}}>
            <StyledTableCell colSpan={8}/>
          </StyledTableRow>)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <RedIconButton
                style={{margin: '-15px -20px -15px -8px'}}
                TooltipProps={{
                  title: `Personify service${Object.values(personify) > 1 ? 's' : ''}`,
                }}
                disabled={Object.values(personify).length === 0}
                onClick={servicePersonify(personify)}
              >
                <Badge
                  badgeContent={Object.values(personify).length}
                  color="error"
                >
                  <FontAwesomeIcon icon={faEye}/>
                </Badge>
              </RedIconButton>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={7}
              count={count}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'}, native: true,
              }}
              page={Math.floor(from / rowsPerPage)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    {confirmDelete && (<ConfirmAction
      action={`Delete ${confirmDelete.name || confirmDelete.hash}?`}
      question={`Do you really want to delete the service: ${confirmDelete.name || confirmDelete.hash} ?`}
      actionName="Delete"
      handleClose={() => setConfirmDelete(false)}
      handleAction={() => setDeleting(confirmDelete) || setConfirmDelete(false)}
    />)}
  </BoxInfo>);
}

ServicesPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default withRouter(ServicesManagement);

/**
 *
 * @param{string} expire
 * @return {JSX.Element}
 * @constructor
 */
function Expired({expire}) {
  const exp = moment(expire, 'DD-MM-YYYY');
  const now = moment();
  const diff = exp.diff(now, 'months');
  const diffDays = exp.diff(now, 'days');
  let color = '';
  if (diff <= 3 && diffDays > 0) {
    color = 'orange';
  } else if (diffDays <= 0) {
    color = 'red';
  }
  return (<>
    {!!color && (<span style={{color}}>
      <b>{expire}</b>
    </span>)}
    {!color && expire}
  </>);
}

Expired.propTypes = {
  expire: PropTypes.string,
};
const redTextFieldProps = {
  fullWidth: true,
  select: true,
  size: 'small',
  variant: 'outlined',
  SelectProps: {
    multiple: true, MenuProps: {
      anchorOrigin: {
        vertical: 'bottom', horizontal: 'left',
      }, transformOrigin: {
        vertical: 'top', horizontal: 'left',
      }, getContentAnchorEl: null,
    },
  },
};

/**
 *
 * @param{function} handleClose
 * @param{function} onCreation
 * @param{boolean} isOpen
 * @param{string} superGroupId
 * @param{object|boolean} service
 * @return {JSX.Element}
 * @constructor
 */
function UpsertService({
  handleClose, onCreation, open: isOpen, super_group_id: superGroupId, service,
}) {
  const [open, setOpen] = useState(isOpen);

  const [id, setId] = useState('');
  const [hash, setHash] = useState('');
  const [name, setName] = useState('');
  const [internal, setInternal] = useState(false);
  const [mqps, setMQPS] = useState(150);
  const [expire, setExpire] = useState('');
  const [dns, setDNSServers] = useState([]);
  const [clientDns, setClientDNSServers] = useState([]);
  const [info, setServiceInfo] = useState([]);
  const [slaves, setSlaves] = useState([]);

  const [upserting, setUpserting] = useState(false);

  useEffect(() => {
    async function getSlaves() {
      const data = await getFetch('provisioning/slaves');
      if (data.slaves) {
        setSlaves(data.slaves);
      }
    }

    getSlaves();
  }, []);

  useEffect(() => {
    async function getServiceInfo(hash) {
      if (hash) {
        const data = await getFetch(`services/info/${hash}`);
        if (data.info) {
          setServiceInfo(data.info);
        }
      }
    }

    getServiceInfo(hash);
  }, [hash]);


  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (info.length) {
      setClientDNSServers(info[0].ips);
    }
  }, [info]);

  useEffect(() => {
    if (open && service?.attributes?.group_info) {
      const {
        internal: serviceInternal,
        expire: serviceExpire,
        name: serviceName,
        mqps: serviceMqps,
        dns: serviceDns,
      } = JSON.parse(service?.attributes?.group_info);
      setId(service.id || '');
      setHash(service.name || '');
      setName(serviceName || '');
      setInternal(serviceInternal || false);
      setMQPS(serviceMqps || 0);
      setExpire(serviceExpire || '');
      setDNSServers(serviceDns || []);
    } else if (open) {
      setId('');
      setHash('');
      setName('');
      setInternal(false);
      setMQPS(0);
      setExpire('');
      setClientDNSServers([]);
      setDNSServers([]);
    }
  }, [service, open]);

  useEffect(() => {
    if (!upserting?.updateGroup) {
      return;
    }
    setUpserting((u) => ({...u, updateGroup: false}));

    if (!id) {
      postFetch(`service/${superGroupId}`, {
        name: hash, attributes: {
          group_info: [JSON.stringify({
            internal: !!internal, expire, name, mqps, hash, dns,
          })],
        },
      }).then((data) => {
        setId(data.id);
        setUpserting((o) => {
          const {...newO} = o;
          delete newO.updateGroup;
          return newO;
        });
      }).catch((error) => {
        displayError(`Error creating service`, error?.response?.data?.errorMessage || 'Unknown error.');
        setUpserting(false);
      });
    } else {
      putFetch(`service/${id}`, {
        ...service, attributes: {
          group_info: [JSON.stringify({
            internal: !!internal, expire, name, mqps, hash, dns,
          })],
        },
      }).then(() => {
        putFetch(`services/${hash}`, {
          expire, name, ips: clientDns, dnsips: dns,
        }).then(() => {
          setUpserting((o) => {
            delete o.updateGroup;
            return {...o};
          });
        });
      }).catch((error) => {
        displayError(`Error updating service`, error?.response?.data?.errorMessage || 'Unknown error.');
        setUpserting(false);
      });
    }
  }, [upserting, id, name, hash, expire, service, internal, mqps, superGroupId, dns, clientDns]);

  useEffect(() => {
    if (upserting && !Object.entries(upserting).length) {
      setUpserting(false);
      handleClose();
      onCreation();
      displaySuccess(`Saving service`, `Service info was saved`);
    }
  }, [upserting, handleClose, onCreation, service.id]);
  return (<Dialog open={open}>
    <DialogTitle>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {service?.id && <b>Edit {service.attributes.name || service.name}</b>}
        {!service?.id && <b>New Service</b>}
      </div>
    </DialogTitle>
    <DialogContent>
      <TableMui>
        <TableBody>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Hash</b>
            </TableCell>
            <TableCell>
              <RedTextField
                {...redTextFieldProps}
                select={false}
                disabled={!!id}
                value={hash}
                onChange={({target: {value}}) => setHash(value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <RedTextField
                {...redTextFieldProps}
                select={false}
                value={name}
                onChange={({target: {value}}) => setName(value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Internal Plugin?</b>
            </TableCell>
            <TableCell>
              <Checkbox
                color="default"
                size="small"
                checked={internal}
                onChange={({target: {checked}}) => setInternal(checked)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Max Queries per sec.</b>
            </TableCell>
            <TableCell>
              <RedTextField
                {...redTextFieldProps}
                select={false}
                value={mqps}
                onChange={({target: {value}}) => setMQPS(value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Service expired at</b>
            </TableCell>
            <TableCell>
              <RedTextField
                {...redTextFieldProps}
                select={false}
                placeholder="dd-mm-yyyy"
                value={expire}
                onChange={({target: {value}}) => setExpire(value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>DNS8 Servers</b>
            </TableCell>
            <TableCell>
              <RedTextField
                {...redTextFieldProps}
                value={dns}
                onChange={({target: {value}}) => setDNSServers(value)}
              >
                {Array.from(new Set(dns.concat(slaves))).map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>))}
              </RedTextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{textAlign: 'right'}}>
              <b>Client DNS Servers</b>
            </TableCell>
            <TableCell>
              <SelectMultipleSearch
                tokenValidator={(t) => validator.isIP(t) || validator.isIPRange(t)}
                value={clientDns}
                onChange={({target: {value}}) => setClientDNSServers(value)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </TableMui>
    </DialogContent>
    <DialogActions style={{justifyContent: 'space-between'}}>
      <FormControl>
        <RedButton
          disabled={!hash || !name || !!upserting}
          onClick={() => setUpserting({updateGroup: true})}
          variant="outlined"
        >
            Save
        </RedButton>
      </FormControl>
      <FormControl>
        <RedButton variant="outlined" color="secondary"
          onClick={handleClose}>
            Cancel
        </RedButton>
      </FormControl>
    </DialogActions>
  </Dialog>);
}

UpsertService.propTypes = {
  handleClose: PropTypes.func,
  onCreation: PropTypes.func,
  open: PropTypes.bool,
  super_group_id: PropTypes.string,
  service: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

/**
 *
 * @param{any} action
 * @param{any} question
 * @param{any} actionName
 * @param{any} handleClose
 * @param{any} handleAction
 * @return {JSX.Element}
 * @constructor
 */
function ConfirmAction({
  action, question, actionName, handleClose, handleAction,
}) {
  return (<Dialog open={true}>
    <DialogTitle>{action}</DialogTitle>
    <DialogContent>{question}</DialogContent>
    <DialogActions style={{justifyContent: 'space-between'}}>
      <RedButton variant="outlined" onClick={handleAction}>
        {actionName}
      </RedButton>
      <RedButton variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
      </RedButton>
    </DialogActions>
  </Dialog>);
}

ConfirmAction.propTypes = {
  action: PropTypes.any,
  question: PropTypes.any,
  actionName: PropTypes.any,
  handleClose: PropTypes.func,
  handleAction: PropTypes.func,
};
