import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Table} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUserMinus,
} from '@fortawesome/free-solid-svg-icons';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import PropTypes from 'prop-types';
import {getFetch, postFetch} from '../../../ApiBackend';
import InactiveActiveSwitchState
from '../../../Components/InactiveActiveSwitchState';
import BoxInfo from '../../../Components/BoxInfo';
import RedIconButton from '../../../core/components/buttons/RedIconButton';
import {StyledTableCell} from '../../../Components/Table';
import Spinner from '../../../Components/Spinner';
import configs from '../../../configs';

/**
 *
 * @param{*} service
 * @return {JSX.Element}
 * @constructor
 */
export function EndpointHostsTable({service}) {
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [keycloak] = useSelector((state) => [
    state.keycloak,
  ]);
  const roles =
    keycloak.tokenParsed.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];
  const canEdit = roles.includes('root') || roles.include('admin');


  useEffect(() => {
    setLoading(true);
    setError(false);
    getFetch(`service/${service}/endpoint/members`)
        .then((data) => {
          setHosts(data?.hosts);
          setLoading(false);
          setError(false);
        },
        ).catch(() => {
          setLoading(false);
          setError(true);
        });
  }, [service]);


  const onChangeAgentAutoStart = (agentId, autoStart) => (e) => {
    postFetch(`service/${service}/endpoint/${agentId}/autostart`,
        {autoStart: !autoStart})
        .then(() => {
          setHosts((hosts) => hosts.map((h) => {
            if (h.clientId === agentId) {
              return {...h, autoStart: !autoStart};
            }
            return h;
          }));
        });
  };

  const onChangeAgentStatus = (agentId, currentStatus) => (e) => {
    postFetch(`service/${service}/endpoint/${agentId}/status`,
        {status: currentStatus ? 'disabled' : 'enabled'})
        .then(() => {
          setHosts((hosts) => hosts.map((h) => {
            if (h.clientId === agentId) {
              return {...h, status: currentStatus ? 'disabled' : 'enabled'};
            }
            return h;
          }));
        });
  };

  const columns = [
    {
      props: {style: {textAlign: 'center', width: 0}},
      data_props: {style: {textAlign: 'center', width: 0}},
      title: <></>,
      data: 'status',
      hide: !canEdit,
      render: (status, row) => {
        return <RedIconButton
          alwaysTooltip={true}
          TooltipProps={{title: 'Unlicensed agent'}}
          disabled={status === 'unlicensed'}>
          <FontAwesomeIcon icon={faUserMinus}/>
        </RedIconButton>;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Agent Status</b>,
      data: 'status',
      render: (status, row) => {
        const s = status === 'enabled';
        return <InactiveActiveSwitchState
          checked={s}
          sideEffect={onChangeAgentStatus(row.clientId, s)}
          label={status ? status[0].toUpperCase() + status.slice(1) : 'Unknown'}
          disabled={!canEdit || !status}
        />;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Hostname</b>,
      data: 'hostname',
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Interfaces - Mac Address</b>,
      data: 'interfaces',
      render: (interfaces) => {
        return <ul style={{listStyle: 'none', padding: 0}}>
          {interfaces?.map((i) => <li
            key={i.mac_address}>{i.ip} - {i.mac_address}</li>)}
        </ul>;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Agent Id</b>,
      data: 'clientId',
      render: (clientId) => clientId?.replace('service-account-', ''),
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>AutoStart</b>,
      data: 'autostart',
      hide: !canEdit,
      render: (autoStart, row) => {
        const as = autoStart === 'true';
        return <InactiveActiveSwitchState
          checked={as}
          sideEffect={onChangeAgentAutoStart(row.clientId, as)}
        />;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Version</b>,
      data: 'version',
    },
  ];
  return (
    <>
      {!loading && !error && !!hosts.length && (
        <BoxInfo
          title={
            <b>Hosts with Agent Endpoinds</b>
          }>
          <Table style={{width: '100%', tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  const columnKey = `header${index}`;
                  if (col.hide) return null;
                  return (
                    <StyledTableCell key={columnKey}
                      style={col?.props?.style}>
                      {col.title || ''}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(loading || error) && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    <Spinner/>
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading && !error && !hosts.length && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    No Data
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading &&
                !error &&
                !!hosts.length &&
                hosts.map((d, rIndex) => {
                  const row = d || {};
                  const rowKey = `row${rIndex}`;
                  return (
                    <TableRowStyled
                      key={rowKey}
                      className={
                        rIndex % 2 ?
                          'platinum-bg  opacity-30' :
                          'white-bg'
                      }
                    >
                      {columns.map(function(column, cIndex) {
                        if (column.hide) return null;
                        let cellData;
                        if (row[cIndex]) {
                          cellData = row[cIndex];
                        } else if (column.render) {
                          cellData = column.render(row[column.data], row);
                        } else {
                          cellData = row[column.data];
                        }
                        const cellKey = `data_${rIndex}_${cIndex}`;
                        return (
                          <StyledTableCell
                            key={cellKey}
                            style={column?.data_props?.style}
                          >
                            {cellData}
                          </StyledTableCell>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
            </TableBody>
          </Table>
        </BoxInfo>
      )}
      {loading && <BoxInfo
        title={<><Spinner/> <b>Loading Endpoint Hosts info ... </b></>}/>}
    </>
  );
}

EndpointHostsTable.propTypes = {
  service: PropTypes.any,
  info: PropTypes.any,
};


/**
 *
 * @param{object} style
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function TableRowStyled({style = {}, ...props}) {
  return <TableRow {...props}
    style={{
      border: '1px solid #F9F9F9',
      height: '38px', ...style,
    }}/>;
}

TableRowStyled.propTypes = {
  style: PropTypes.object,
};
