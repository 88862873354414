import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRocket, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import {faEyeSlash} from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import {useGetDataBackend, usePostDataBackend} from '../../ApiBackend';
import {setPath} from '../../actions/pathAction';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {
  setNotifications as setGlobalNotifications,
} from '../../actions/notificationsActions';

export default function Notifications() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath('Notifications'));
  }, [dispatch]);

  const refreshUtil = useState(0);
  const oldNotification = useState();
  const [openOld, SetOpenOld] = useState(false);

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
      paddingBottom: 7,
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <p style={{color: '#464646'}}>&nbsp;</p>
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <div style={{marginTop: 104, fontSize: 20, color: '#333333'}}>
          <b>&nbsp;</b>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 14}}/>
      </Grid>
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <NewAndPinNotifications
          refresh_util={refreshUtil}
          oldNotification={oldNotification}
        />
        {!openOld && (
          <ButtonForm onClick={() => SetOpenOld(true)}>
            See Old Notifications
          </ButtonForm>
        )}
        {openOld && <Divider style={{marginTop: 14, marginBottom: 14}}/>}

        {openOld && (
          <SeenAndOldNotifications2
            refresh_util={refreshUtil}
            oldNotification={oldNotification}
          />
        )}
      </Grid>
    </Grid>
  );
}

function SeenAndOldNotifications2({
  oldNotification: [seenNotification],
  refresh_util: [_, setRefresh],
}) {
  const [after, setAfter] = useState(moment().format('x'));
  const lastNoti = useRef(moment().format('x'));

  const {
    data: {notifications = []},
    loading,
    error,
  } = useGetDataBackend('users/notifications/old', {after});

  const [notes, setNotifications] = useState(notifications);

  const [note, setUpdateNote] = useState(undefined);

  useEffect(() => {
    setNotifications((nots) => {
      if (
        seenNotification &&
        seenNotification['@timestamp'] >= lastNoti.current
      ) {
        return [...nots, seenNotification].sort((a, b) => {
          return b['@timestamp'] - a['@timestamp'];
        });
      }
      return nots;
    });
  }, [seenNotification]);

  useEffect(() => {
    if (!loading && !error && !notifications.length) {
      lastNoti.current = 0;
    } else if (notifications.length && !loading && !error) {
      setNotifications((nots) => [...nots, ...notifications]);

      lastNoti.current = notifications[notifications.length - 1]['@timestamp'];
    }
  }, [notifications, loading, error]);

  const updateAfter = () => {
    setAfter(lastNoti.current);
  };

  const pinned = function(selectedNote) {
    return () => {
      const n = {...selectedNote};
      if (!n['@pin']) {
        n['@pin'] = moment().format('x');
        delete n['@seen'];
      } else {
        delete n['@pin'];
      }
      setNotifications((oldNotes) => [
        ...oldNotes.filter((oldNote) => {
          const {_id: oldNoteId} = oldNote;
          const {_id: currentNoteId} = selectedNote;
          return oldNoteId !== currentNoteId;
        }),
      ]);
      setUpdateNote(n);
    };
  };

  return (
    <>
      {!!notes.length &&
        notes.map((n) => {
          const {_id} = n;
          return (
            <DNS8Notification
              {...n}
              key={_id}
              alwaysShow={true}
              pinned={pinned(n)}
            />
          );
        })}
      {!!lastNoti.current && (
        <ButtonForm onClick={updateAfter}>Load More</ButtonForm>
      )}
      {!!note && (
        <UpsertNote
          note={note}
          unset={() => setUpdateNote(null)}
          refresh={() => setRefresh((s) => s + 1)}
        />
      )}
    </>
  );
}

function NewAndPinNotifications({
  refresh_util: [refresh, setRefresh],
  oldNotification: [, setOldNotification],
}) {
  const dispatch = useDispatch();

  const [notes, setNotifications] = useState([]);
  const [note, setUpdateNote] = useState(null);

  const {
    data: {notifications = []},
    loading,
    error,
  } = useGetDataBackend('users/notifications', {refresh});

  useEffect(() => {
    if (!loading && !error) {
      setNotifications(notifications);
      console.log('sendUpdateToUser');
      dispatch(setGlobalNotifications(notifications));
    }
  }, [notifications, dispatch, loading, error]);

  const markAsSeen = function(selectedNote) {
    return () => {
      const newNote = {...selectedNote};
      if (!newNote['@seen']) {
        newNote['@seen'] = moment().format('x');
        delete newNote['@pin'];
      } else {
        delete newNote['@seen'];
      }
      setUpdateNote(newNote);
    };
  };

  const pinned = function(selectedNote) {
    return () => {
      const newNote = {...selectedNote};
      if (!newNote['@pin']) {
        newNote['@pin'] = moment().format('x');
        delete newNote['@seen'];
      } else {
        delete newNote['@pin'];
      }
      setUpdateNote(newNote);
    };
  };

  return (
    <>
      {loading && !error && <p>Loading your notifications.</p>}
      {!notes.length && !loading && !error && <p>No new/pin Notifications</p>}
      {!!notes.length &&
        notes.map((notification) => {
          const {_id} = notification;
          return (
            <DNS8Notification
              {...notification}
              key={_id}
              pinned={pinned(notification)}
              markAsSeen={markAsSeen(notification)}
            />
          );
        })}
      {!!note && (
        <UpsertNote
          note={note}
          unset={() => setUpdateNote(null)}
          refresh={() => setOldNotification(note) ||
            setRefresh((s) => s + 1)}
        />
      )}
    </>
  );
}

function UpsertNote({note, unset, refresh}) {
  const [n, setNote] = useState(note);
  useEffect(() => {
    if (n) {
      setNote(n);
    }
  }, [n]);
  const {loading, error, data} = usePostDataBackend('users/notifications', n);
  useEffect(() => {
    if (!loading && !error) {
      unset();
    }
  }, [loading, error, data, refresh, unset]);
  useEffect(() => {
    return () => refresh();
  }, [refresh]);

  return false;
}

const notificationMap = {
  'release-notes': {
    severity: 'info',
    icon: (
      <FontAwesomeIcon
        icon={faRocket}
        style={{width: 22}}
        transform={{rotate: -45}}
      />
    ),
    color: '#2196f3',
  },
  'warning': {
    severity: 'warning',
    icon: undefined,
    color: undefined,
  },
  'error': {
    severity: 'error',
    icon: undefined,
    color: undefined,
  },
};

function DNS8Notification({
  type,
  title,
  description,
  setNotifications,
  alwaysShow = false,
  pinned,
  markAsSeen,
  ...cd
}) {
  const [show, setShow] = useState(!cd['@seen'] || alwaysShow);
  useEffect(() => {
    setShow(!cd['@seen'] || alwaysShow);
  }, [cd, alwaysShow]);
  const {icon, severity, color} = notificationMap[type || 'release-notes'];
  return (
    !!show && (
      <Alert
        icon={icon}
        severity={severity}
        variant={!cd['@pin'] ? 'outlined' : 'standard'}
        style={{margin: '7px 0'}}
        action={
          <div style={{padding: '7px 0'}}>
            <RedIconButton
              TooltipProps={{title: 'Mark as read'}}
              disabled={alwaysShow || !show}
              onClick={markAsSeen}
            >
              <FontAwesomeIcon icon={faEyeSlash}/>
            </RedIconButton>
            <RedIconButton
              TooltipProps={{
                title: !cd['@pin'] ? 'Pin Notification' : 'Unpin',
              }}
              onClick={pinned}
            >
              <FontAwesomeIcon
                icon={faThumbtack}
                color={!cd['@pin'] ? 'unset' : color}
                transform={{rotate: !cd['@pin'] ? 90 : 45}}
              />
            </RedIconButton>
          </div>
        }
      >
        <AlertTitle>{title}</AlertTitle>
        {description}
        <div style={{marginTop: '.35rem', marginBottom: '-2px'}}>
          <i>
            <b>{moment(cd['@timestamp'], 'x').format('HH:mm DD-MM-YYYY')}</b>
          </i>
        </div>
      </Alert>
    )
  );
}
