import React from 'react';
import {Button, Tooltip, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';


const RedButtonBase = withStyles({
  root: {
    '& .MuiButton-iconSizeSmall > *:first-child, & .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 'inherit',
    },
    'background': '#464646',
    'borderColor': '#464646',
    'color': '#FFFFFF',
    '&.Mui-disabled': {
      background: '#E4E4E4', borderColor: '#E4E4E4',
    },
    '&:hover': {
      background: '#BE131A', borderColor: '#BE131A',
    },
    '&.MuiButton-text': {
      'color': '#464646',
      'background': 'none',
      'borderColor': 'unset',
      '&:hover': {
        color: '#BE131A',
      },
    },
    '&.MuiButton-outlined': {
      'color': '#464646',
      'background': 'none',
      'border': '1px solid #464646',
      '&:hover': {
        color: '#BE131A', border: '1px solid #BE131A',
      },
    },
    '&.sec, &.MuiButton-outlinedSecondary': {
      'background': 'white', 'color': '#464646', '&:hover': {
        background: '#BE131A', color: '#FFFFFF',
      }, '&:hover *': {
        color: '#FFFFFF',
      }, '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF',
        },
      },
    },
    '&.false-dga': {
      'background': 'white',
      'color': '#464646',
      'borderColor': '#EDAE30',
      '&:hover': {
        background: '#BE131A', borderColor: '#BE131A', color: '#FFFFFF',
      },
      '&:hover *': {
        color: '#FFFFFF',
      },
      '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF',
        },
      },
    },
    '&.vt': {
      'background': 'white',
      'color': '#464646',
      'borderColor': '#1870B4',
      '&:hover': {
        background: '#BE131A', borderColor: '#BE131A', color: '#FFFFFF',
      },
      '&:hover *': {
        color: '#FFFFFF',
      },
      '&.Mui-disabled': {
        'background': '#E4E4E4',
        'borderColor': '#E4E4E4',
        'color': '#FFFFFF',
        '& *': {
          color: '#FFFFFF !important',
        },
      },
    },
  },
})(Button);

/**
 *
 * @param{{}} TooltipProps
 * @param{boolean} alwaysTooltip
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function RedButton({TooltipProps, alwaysTooltip, ...props}) {
  if (TooltipProps && (!props.disabled || alwaysTooltip)) {
    return (<Tooltip {...TooltipProps}>
      <RedButtonBase {...props} />
    </Tooltip>);
  }
  return <RedButtonBase {...props} />;
}

RedButton.defaultProps = {
  color: 'primary',
  disabled: false,
  disableElevation: false,
  disableFocusRipple: false,
  disableRipple: false,
  endIcon: null,
  fullWidth: false,
  size: 'medium',
  startIcon: null,
  variant: 'text',
  TooltipProps: null,
  alwaysTooltip: false,
};

RedButton.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The color of the component. It supports those theme colors that make sense
   * for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOfType([PropTypes.oneOf(['inherit', 'primary', 'secondary']), PropTypes.string]),
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, no elevation is used.
   * @default false
   */
  disableElevation: PropTypes.bool,
  /**
   * If `true`, the  keyboard focus ripple is disabled.
   * @default false
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * If `true`, the ripple effect is disabled.
   *
   * ⚠️ Without a ripple there is no styling for :focus-visible by default.
   * Be sure to highlight the element by applying separate styles with the
   * `.Mui-focusedVisible` class.
   * @default false
   */
  disableRipple: PropTypes.bool,
  /**
   * Element placed after the children.
   */
  endIcon: PropTypes.node,
  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'medium'
   */
  size: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.string]),
  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,
  /**
   * The variant to use.
   * @default 'text'
   */
  variant: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text']), PropTypes.string]),
  /**
   * Props applied to the Tooltip element.
   * only if the button is not disabled.
   */
  TooltipProps: PropTypes.object,
  /**
   * Flag to indicate tooltip in disable RedButton.
   */
  alwaysTooltip: PropTypes.bool,
};

export default RedButton;
