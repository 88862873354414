import {Switch, withStyles} from '@material-ui/core';
import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const InactiveActiveSwitch = withStyles({
  switchBase: {
    'color': '#888888',
    '&$checked': {
      color: '#2C7847',
    },
    '&$checked + $track': {
      backgroundColor: '#2C7847',
    },
  },
  checked: {},
  disabled: {
    '&$checked .MuiSwitch-thumb': {
      backgroundColor: '#bcdfc6',
    },
  },
  track: {
    backgroundColor: '#999999',
  },
})(Switch);

export default function InactiveActiveSwitchState({
  sideEffect = () => null,
  ...props
}) {
  const [state, setState] = useState(props.checked || false);
  const onChange = (event) => {
    setState(event.target.checked);
    sideEffect();
  };

  const label = props.label ?? (state ? 'Active' : 'Inactive');

  return (
    <>
      <FormControlLabel
        onChange={onChange}
        control={<InactiveActiveSwitch {...props} checked={state}/>}
        label={label}
      />
    </>
  );
}
