import React, {useEffect, useState} from 'react';
import {
  Slider,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import {RenderCategory} from '../Category';
import {useGetDataBackend, usePostDataBackend} from '../../ApiBackend';
import Spinner from '../../Components/Spinner';
import {displayError} from '../../ui-components/displayMsg';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PolicyIcon from '@mui/icons-material/Policy';
import PropTypes from 'prop-types';

/**
 *
 * @param{string} hash
 * @return {JSX.Element|unknown[]}
 * @constructor
 */
export default function CategoriesList({hash}) {
  const {
    data: {categories},
    error,
    loading,
  } = useGetDataBackend('services/categories', {clientname: hash});
  const [clientCats, setData] = useState([]);
  useEffect(() => {
    let update = true;
    if (!loading && !error && update) {
      setData(categories[hash] || []);
    }
    return () => {
      update = false;
    };
  }, [hash, error, loading, categories]);

  if (loading || error || clientCats.length === 0) {
    return <CategoriesListPlaceholder/>;
  }
  return clientCats.map((e, i) => {
    const catKey = 'cat-' + i;
    return <CategoryList {...e} key={catKey}/>;
  });
}
CategoriesList.propTypes = {
  hash: PropTypes.string,
};

/**
 *
 * @param{boolean} block
 * @param{boolean} validated
 * @return {number}
 */
function convertStateToValue(block, validated) {
  if (block) {
    return 2;
  }
  if (validated) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param{string} id
 * @param{string} description
 * @param{boolean} block
 * @param{any} type
 * @param{boolean|undefined} validated
 * @return {JSX.Element}
 * @constructor
 */
function CategoryList({id, description, block, type, validated}) {
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [updating, setUpdate] = useState();
  const [value, setValue] = useState(convertStateToValue(block, validated));
  const step = validated === undefined ? 2 : 1;
  if (id=== 'tunneling') {
    return <></>;
  }
  return (
    <div
      key={id}
      style={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'white',
        minHeight: 55,
        padding: '0 20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '5px 0',
        color: '#464646',
      }}
    >
      <div
        style={{
          margin: 'auto 20px auto 0',
          textTransform: 'capitalize',
          width: 110,
        }}
      >
        <RenderCategory list={id}/>
      </div>
      {!sm && (
        <span
          style={{
            fontSize: 12,
            lineHeight: '1',
            margin: 'auto',
            flex: 1,
            padding: 10,
          }}
        >
          {description}
        </span>
      )}
      <div style={{
        // margin: 'auto 0',
        width: 125,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '15px 25px 0 5px',
      }}>
        {id !== 'tunneling' && type && (
          <ProtectionLevelSlider
            value={value}
            step={step}
            min={0}
            valueLabelFormat={(v) => settingsProperties[v].label}
            max={2}
            valueLabelDisplay="on"
            onChange={(ev, v) => setValue(v)}
            ThumbComponent={ProtectionLevelThumbComponent}
            onChangeCommitted={(ev, v) => {
              setUpdate({
                id,
                type,
                block: v === 2,
                validated: (validated !== undefined) && v > 0,
              });
            }}
          />
        )}
        {id === 'tunneling' && type && (
          <small style={{'marginTop': '-15px', 'textAlign': 'center'}}><b>Coming Soon</b></small>
        )}
        {!type && <Spinner style={{fontSize: 30, margin: 'auto'}}/>}
        {updating && (
          <UpdateClientCategory
            {...updating}
            sideEffect={() => setUpdate(false)}
          />
        )}
      </div>
    </div>
  );
}

CategoryList.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  block: PropTypes.bool,
  type: PropTypes.any,
  validated: PropTypes.bool,
};

const settingsProperties = [
  {label: 'Monitor Only', color: '#AAA', icon: PolicyIcon},
  {label: 'Block Validated', color: '#2C7847', icon: GppGoodIcon},
  {label: 'Block All', color: '#2C7847', icon: LocalPoliceIcon},
];

/**
 *
 * @param{Element} children
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function ProtectionLevelThumbComponent({children, ...props}) {
  const Icon = settingsProperties[props['aria-valuenow'] || 0].icon;
  return <>
    <span {...props}>
      <Icon/>
    </span>
    {children}

  </>;
}

ProtectionLevelThumbComponent.propTypes = {
  'aria-valuenow': PropTypes.number,
  'children': PropTypes.element,
};

const ProtectionLevelSlider = withStyles({
  root: { // padding + height = 28
    'color': ({value}) => settingsProperties[value]?.color,
    'height': 28,
    'padding': '0',
  },
  thumb: {
    'height': 30,
    'width': 30,
    'top': '50%',
    'left': '50%',
    'marginLeft': -15,
    'marginTop': -15,
    'backgroundColor': 'white',
    'border': '1px solid currentColor',
    'boxShadow': 'none',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 0px 8px 4px currentColor',
    },
    '& *': {
      margin: 'auto',
    },
  },
  valueLabel: {
    'top': -25,
    'bottom': 0,
    'left': 0,
    'right': 0,
    'textAlign': 'center',
    'transform': 'unset',
    '& *': {
      background: 'transparent',
      color: '#000',
      fontSize: 12,
      width: '100%',
    },
  },
  active: {},
  track: {
    display: 'none',
  },
  rail: {
    width: 'calc(100% + 30px)',
    height: 20,
    borderRadius: '10px',
    left: '-15px',
    top: 4,
    opacity: ({value}) => value === 2 ? 1 : 0.38,
  },
})(Slider);

/**
 *
 * @param{func} sideEffect
 * @param{{}} doc
 * @return {JSX.Element}
 * @constructor
 */
function UpdateClientCategory({sideEffect = () => null, ...doc}) {
  const {loading, error} = usePostDataBackend('services/category', {
    ...doc,
  });
  useEffect(() => {
    if (!loading && !error) {
      sideEffect();
    } else if (error) {
      let msg = 'allowing';
      if (doc.block) {
        msg = 'blocking';
      } else if (doc.validated) {
        msg = 'blocking validated';
      }
      displayError(
          <>Error {msg} category:</>,
          error,
      );
      sideEffect();
    }
  }, [doc, loading, error, sideEffect]);

  return <></>;
}

UpdateClientCategory.propTypes = {
  sideEffect: PropTypes.func,
  doc: PropTypes.object,
};

/**
 *
 * @return {Element[]}
 * @constructor
 */
export function CategoriesListPlaceholder() {
  const categories = [
    {
      order: 4,
      description: 'The IPs/Domains listed are known for hosting malware, ' +
        'botnets, worms, drones, or somehow participating in related' +
        ' cyber crime activities.',
      id: 'malware',
    },
    {
      order: 4.5,
      description: 'The domains listed are known for trying to deceive users' +
        ' to obtain theirs informations.',
      id: 'phishing',
    },
    {
      order: 5,
      description: 'The IPs/domains listed have somehow bad reputation.' +
        ' There is no clear evidence of abuse, attack or malware.' +
        ' IP lists that track connection failures to certain services using' +
        ' firewall or IDS logs are an example.',
      id: 'reputation',
    },
    {
      order: 6,
      description: 'The IPs/domains are used by other parties for hiding' +
        ' their real identity.' +
        ' Open proxies, VPN providers, etc fall into this category.',
      id: 'anonymizers',
    },
    {
      id: 'dga',
      order: 7,
      description: 'The domains listed in this category are generated' +
        ' by algorithms.',
    },
    // {
    //   id: 'tunneling',
    //   order: 7.5,
    //   description: 'The domains listed in this category are used to establish' +
    //     ' covert channels for data exfiltration and command-and-control' +
    //     ' communication.',
    // },
    {
      order: 8,
      description: 'The IPs/domains listed are ads and tracking services.',
      id: 'adware',
    },
  ];

  return categories.map((e) => <CategoryList {...e} key={e.id}/>);
}
