import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getFetch, postFetch} from '../../../ApiBackend';
import Grid from '@material-ui/core/Grid';
import BoxInfo from '../../../Components/BoxInfo';
import RedIconButton from '../../../core/components/buttons/RedIconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faEdit, faSave} from '@fortawesome/free-solid-svg-icons';
import {useTheme} from '@material-ui/core';
import InactiveActiveSwitchState
from '../../../Components/InactiveActiveSwitchState';
import RedTextField from '../../../core/components/inputs/RedTextField';
import {useSelector} from 'react-redux';
import configs from '../../../configs';
import {displayError} from '../../../ui-components/displayMsg';
import fileDownload from 'js-file-download';

/**
 *
 * @param{string} service
 * @param{object} info
 * @return {JSX.Element}
 * @constructor
 */
export default function EndpointAgentInfoAndGlobalSettings({service, info}) {
  const padding = useTheme().spacing(3);
  const [globalAutoStart, setGlobalAutoStart] = useState(false);
  const [changeLicences, setChangeLicences] = useState(false);
  const [delta, setDelta] = useState('');
  const [submittingDelta, setSubmittingDelta] = useState(false);
  const [status, setStatus] = useState(info?.status ?? {});

  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const roles =
    keycloak.tokenParsed.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];
  const canEdit = roles.includes('root') || roles.includes('admin');
  const canEditLicences = roles.includes('root');

  const onSaveDelta = (e) => {
    e.preventDefault();
    setSubmittingDelta(true);
    postFetch(`service/${service}/endpoint/licence`, {delta}).then((data) => {
      setSubmittingDelta(false);
      setChangeLicences(false);
      setDelta('');
      setStatus(data?.status ?? {});
    }).catch((e) => {
      e.text().then((data) => {
        setSubmittingDelta(false);
        displayError('Error adding bypass:', data);
      });
    });
  };

  const onChangeGlobalAutoStart = (globalAutoStart) => (e) => {
    postFetch(`service/${service}/endpoint/autoStart`,
        {autoStart: !globalAutoStart})
        .then(() => {
          setGlobalAutoStart(!globalAutoStart);
        });
  };

  const handleDownload = () => {
    let fileName = 'agent.exe';
    getFetch(`service/${service}/endpoint/download`).then(async (response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      fileName = contentDisposition ?
        JSON.parse(contentDisposition.split('filename=')[1]) :
        fileName;
      return await response.blob();
    }).then((file) => {
      fileDownload(file, fileName);
    });
  };


  return <BoxInfo
    title={
      <b>Endpoint Agent Info & Global Settings</b>}>
    <Grid
      item
      xs={12}
      style={{
        paddingLeft: padding / 2,
        paddingRight: padding / 2,
      }}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span>Download Agent:</span>
        <RedIconButton TooltipProps={{title: 'Download Agent'}}
          onClick={handleDownload}>
          <FontAwesomeIcon icon={faDownload}/>
        </RedIconButton>
      </div>
    </Grid>
    <Grid
      item
      xs={12}
      style={{
        paddingLeft: padding / 2,
        paddingRight: padding / 2,
      }}
    >
      <hr/>
      <p> DoH Proxy Ip: {info?.dohProxiesInfo?.ip ?? '-'}</p>
      <p> DoH Proxy Domain: {info?.dohProxiesInfo?.domain ?? '-'}</p>
    </Grid>
    <Grid
      item
      xs={12}
      style={{
        paddingLeft: padding / 2,
        paddingRight: padding / 2,
      }}
    >
      <hr/>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span>Global Auto Start: &nbsp;</span>
        <InactiveActiveSwitchState
          disabled={!canEdit}
          checked={globalAutoStart}
          sideEffect={onChangeGlobalAutoStart(globalAutoStart)}
        />
      </div>
    </Grid>
    <Grid
      item
      xs={12}
      style={{
        paddingLeft: padding / 2,
        paddingRight: padding / 2,
      }}
    >
      <hr/>
      <div style={{display: 'flex', alignItems: 'center', height: '48px'}}>
        Licenses:&nbsp;
        <b>{status?.inUse || 0}</b>&nbsp;in use from {status?.total || 0} in
        total&nbsp;
        {canEditLicences && !changeLicences && <RedIconButton
          TooltipProps={{title: 'Change Licences'}}
          onClick={() => setChangeLicences(true)}
        >
          <FontAwesomeIcon icon={faEdit}/>
        </RedIconButton>}
        {changeLicences && <RedTextField
          variant="outlined"
          size="small"
          placeholder="Delta Licences"
          value={delta}
          disabled={submittingDelta}
          onChange={({target: {value}}) => setDelta(value)}
        > </RedTextField>}
        {changeLicences && delta && <RedIconButton
          TooltipProps={{title: 'Set Delta Licences'}}
          onClick={onSaveDelta}
        >
          <FontAwesomeIcon icon={faSave}/>
        </RedIconButton>}
      </div>
      <hr style={{opacity: 0}}/>
    </Grid>
  </BoxInfo>;
}

EndpointAgentInfoAndGlobalSettings.propTypes = {
  service: PropTypes.string,
  info: PropTypes.object,
};


