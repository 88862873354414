/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {Chip} from '@material-ui/core';
import RedTextField from '../../core/components/inputs/RedTextField';

const filter = createFilterOptions({
  trim: true,
  stringify: (o) => o.label,
});
/**
 *
 * @param{any} props
 * @return {JSX.Element}
 * @constructor
 */
export default function SelectMultipleSearch(props) {
  const {
    value = [],
    placeholder = ',',
    limit = -1,
    onChange = (_) => _,
    tokenValidator = () => true,
    countValues = (v) => v.length,
    limit_text: limitText = `Limit ${limit} reached`,
    noOptionsText = 'No Options',
    helperText = (values, maxLimit = limit) => {
      const vs = countValues(values);
      if (maxLimit !== -1) {
        return `Selected ${vs} of ${maxLimit} max`;
      }
      return `Selected ${vs} options`;
    },
    InputProps = {},
    ChipProps = {},
    ...unusedProps
  } = props;
  if (unusedProps) {
    // console.log('SelectMultipleSearch: unused pros:', unusedProps);
  }

  const defaultValues = value.map((v) => ({label: v}));
  const [v, setValue] = React.useState([...defaultValues]);
  const [options, setOptions] = React.useState([...defaultValues]);
  const [inputValue, setInputValue] = React.useState('');

  const newValues = (text) => {
    if (text !== '') {
      const values = v.map((o) => o.label);
      const tokens = text.split(',').map((newToken) => newToken.trim());
      let newTokens = tokens.filter(
          (token) => token && tokenValidator(token) && !values.includes(token),
      );
      newTokens = Array.from(new Set(newTokens));
      return !!newTokens.length && newTokens;
    }
    return false;
  };

  const isFreeSolo = (values, presentValues, maxLimit) => {
    if (
      maxLimit !== -1 &&
      countValues(v.map((singleV) => singleV.label)) >= maxLimit
    ) {
      return false;
    }

    if (!presentValues) {
      return false;
    }

    return !(
      maxLimit !== -1 &&
      countValues(v.map((singleV) => singleV.label)) +
      countValues(presentValues) >
      maxLimit
    );
  };

  useEffect(() => {
    const defaultValues = value.map((v) => ({label: v}));
    setValue([...defaultValues]);
    if (defaultValues.length && options.length === 0) {
      setOptions([...defaultValues]);
    }
  }, [value, options]);
  return (
    <div style={{width: '100%'}}>
      <Autocomplete
        size="small"
        multiple
        freeSolo={isFreeSolo(v, newValues(inputValue), limit)}
        forcePopupIcon={true}
        value={v}
        inputValue={inputValue}
        options={options}
        filterSelectedOptions
        ChipProps={{...ChipProps, style: {width: '100%', ...ChipProps.style}}}
        noOptionsText={
          newValues(inputValue) &&
          !isFreeSolo(v, newValues(inputValue), limit) ?
            limitText :
            noOptionsText
        }
        filterOptions={(newOptions, params) => {
          const filtered = filter(newOptions, params);
          // Suggest the creation of a new value
          let newListValues = newValues(params.inputValue);
          if (
            !isFreeSolo(v, newListValues, limit) &&
            params.inputValue !== ''
          ) {
            filtered.splice(0, filtered.length);
            return filtered;
          }
          const optionsValues = filtered.map((o) => o.label);
          newListValues =
            newListValues &&
            newListValues.filter(
                (newValue) => !optionsValues.includes(newValue),
            );
          if (newListValues && newListValues.length) {
            filtered.push({
              inputValue: newListValues,
              label: `Add "${newListValues.join('", "')}"`,
            });
          }

          return filtered;
        }}
        onChange={(event, newValue) => {
          const lastValue = newValue.pop();
          const newOptions = [];
          if (typeof lastValue === 'string') {
            const newListValues = newValues(lastValue);
            if (newListValues) {
              newListValues.forEach((newItemValue) => {
                const option = {label: newItemValue};
                newValue.push(option);
                newOptions.push(option);
              });
            }
          } else if (lastValue && lastValue.inputValue) {
            const valueOptions = options.map((o) => o.label);
            lastValue.inputValue.forEach((lastValueItem) => {
              if (valueOptions.includes(lastValueItem)) {
                const index = valueOptions.indexOf(lastValueItem);
                newValue.push(options[index]);
              } else {
                const option = {label: lastValueItem};
                newValue.push(option);
                newOptions.push(option);
              }
            });
          } else if (lastValue) {
            newValue.push(lastValue);
          }
          setOptions([...options, ...newOptions]);
          setValue([...newValue]);
          onChange({
            target: {
              value: [
                ...newValue.map((newValueItem) => newValueItem.label)],
            },
          });
        }}
        renderInput={(params) => (
          <RedTextField
            {...params}
            placeholder={placeholder}
            InputProps={{...params.InputProps, ...InputProps}}
            margin="none"
            variant="outlined"
            helperText={helperText(
                v.map((vItem) => vItem.label),
                limit,
            )}
          />
        )}
        onInputChange={(e, valueInput) => setInputValue(valueInput)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option) => v.map((v) => v.label).includes(option.label)}
        renderTags={(values, getTagProps) => {
          return values.sort((v1, v2) => v1.label.localeCompare(v2.label)).map((option, index) => (
            <Chip
              key={'tag-' + index}
              variant="outlined"
              label={option.label}
              {...getTagProps({index})}
              style={{
                justifyContent: 'space-between',
              }}
            />
          ));
        }}
      />
    </div>
  );
}
SelectMultipleSearch.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  tokenValidator: PropTypes.func,
  countValues: PropTypes.func,
  limit_text: PropTypes.string,
  noOptionsText: PropTypes.string,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
};
