import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getFetch} from '../../../ApiBackend';
import Grid from '@material-ui/core/Grid';
import {ServiceHeader} from '../../../DNS8Components/Pages/ServiceHeader';
import BoxInfo from '../../../Components/BoxInfo';
import RedIconButton from '../../../core/components/buttons/RedIconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {EndpointHostsTable} from './EndpointHostsTable';
import EndpointBypassTable from '../service/EndpointBypassTable';
import {useTheme} from '@material-ui/core';
import EndpointAgentInfoAndGlobalSettings
from './EndpointAgentInfoAndGlobalSettings';

/**
 *
 * @param{object} service
 * @return {JSX.Element}
 * @constructor
 */
export default function EndpointAgentService({service}) {
  const [info, setInfo] = useState({});
  const [globalAutoStart, setGlobalAutoStart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const padding = useTheme().spacing(3);
  const clientHash = service.client_hash;
  const clientName = service.clientname;
  const serviceExpireDate = service.service_expire_date;
  const limit = service.limit;

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    getFetch(`service/${clientHash}/endpoint/status`)
        .then((data) => {
          setInfo(data ?? {});
          setLoading(false);
          setError(false);
        }).catch((_) => {
          setLoading(false);
          setError(true);
        });
  }, [clientHash]);
  return <>
    {(loading || error) && <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: padding / 2,
          paddingRight: padding / 2,
        }}
      >
        <ServiceHeader
          limit={limit}
          serviceExpireDate={serviceExpireDate}
          servicename={clientName}
        />
      </Grid>
      {error &&
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: padding / 2,
            paddingRight: padding / 2,
          }}
        >
          <p>No Agent  Endpoint Configured </p>
        </Grid>
      }
    </React.Fragment>}
    {!loading && !error && <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: padding / 2,
          paddingRight: padding / 2,
        }}
      >
        <ServiceHeader
          limit={limit}
          serviceExpireDate={serviceExpireDate}
          servicename={clientName}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: padding / 2,
          paddingRight: padding / 2,
        }}
      >
        <EndpointAgentInfoAndGlobalSettings
          info={info}
          service={clientHash}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: padding / 2,
          paddingRight: padding / 2,
        }}
      >
        <EndpointHostsTable
          service={clientHash}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: padding / 2,
          paddingRight: padding / 2,
        }}
      >
        <EndpointBypassTable
          service={clientHash}
        />
      </Grid>
    </React.Fragment>}
  </>;
}


