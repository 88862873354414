import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Table as TableMui,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import ButtonForm from '../../Components/Forms/ButtonForm';
import {setPath} from '../../actions/pathAction';
import {ServiceRender} from '../Service';
import {RenderDomainLink, RenderDomainWithList} from '../Domain';
import {RenderCategory} from '../Category';
import {RenderAction} from '../Action';
import Table from '../../Components/Table';
import {RenderCategoryValue} from '../Tables/PublicRequestsTable';
import RedTextField from '../../core/components/inputs/RedTextField';

/**
 *
 * @param{string} el
 * @return {JSX.Element}
 */
function renderClassification(el) {
  return <div
    style={{
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
  >
    {(el &&
        el.split(' ').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ')) ||
      '-'}
  </div>;
}

/**
 *
 * @param{string} el
 * @return {JSX.Element}
 */
function renderMoment(el) {
  return <div style={{width: '100%'}} className="truncate-link">
    {moment(el, 'x').format('DD-MM-YYYY HH:mm:ss')}
  </div>;
}


/**
 * Threats Page
 * @param{string} path
 * @return {JSX.Element}
 * @constructor
 */
export default function Threats({path = 'Threats'}) {
  const [data, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPath(path));
  }, [dispatch, path]);

  const [timeRange, selectedService, services] = useSelector((state) => [
    state.time_range,
    state.selectedService,
    state.services,
  ]);
  const small = useMediaQuery((t) => t.breakpoints.down('sm'));
  const columns = [
    {
      name: <b>Service</b>,
      data: 'clientname',
      render: function renderService(el) {
        return <ServiceRender hash={el}/>;
      },
      styles: {
        width: 115,
      },
      hide: small || selectedService !== 'all' || services.length <= 1,
    },
    {
      name: <b>Domain</b>,
      data: 'resolved_domain',
      render: function renderDomain(domain, row) {
        const list = row.list[0];
        if (small) {
          const a = row.action === 'PASSTHRU' ? row.action : 'blocked';
          const action = <RenderAction action={a} withLabel={false}/>;
          return (
            <RenderDomainWithList
              domain={domain}
              list={list}
              domains={domain}
              action={action}
            />
          );
        }
        return (
          <RenderDomainWithList domain={domain} list={list}
            domains={domain}/>
        );
      },
      sortable: true,
      searchable: true,
      styles: {
        minWidth: 120,
      },
    },
    {
      name: <b>Hits</b>,
      render: function RenderHit(el) {
        return <div style={{textAlign: 'right', paddingRight: 10}}>
          {el || '-'}
        </div>;
      },
      data: 'hits',
      sortable: true,
      styles: {
        width: 70,
      },
      hide: small,
    },

    {
      name: <b>Classification</b>,
      render: renderClassification,
      data: 'cat',
      searchable: true,
      hide: small,
    },
    {
      name: <b>Last Action</b>,
      options: [
        {label: <em>All</em>, value: ''},
        {label: <RenderAction action="blocked"/>, value: 'blocked'},
        {label: <RenderAction action="allowed"/>, value: 'allowed'},
      ],
      render: function renderAction(el) {
        const element = el === 'PASSTHRU' ? el : 'blocked';
        return <RenderAction action={element}/>;
      },
      data: 'last_action',
      styles: {
        width: 120,
      },
      hide: small,
    },
    {
      name: <b>First Seen</b>,
      data: 'first_seen',
      render: renderMoment,
      sortable: true,
      styles: {
        width: 140,
      },
      hide: small,
    },
    {
      name: <b>Last Seen</b>,
      data: 'last_seen',
      render: renderMoment,
      sortable: true,
      styles: {
        width: 140,
      },
      hide: small,
    },
    {
      title: '',
      sortable: false,
      searchable: false,
      render: function renderInfo(_, row) {
        return <IconButton onClick={() => setOpen(row)} size="small">
          <FontAwesomeIcon icon={faInfoCircle}/>
        </IconButton>;
      },
      styles: {
        width: 56,
      },
      hide: !small,
    },
  ];

  const [cat, setCat] = useState([]);
  const selection = (
    <RedTextField
      select={true}
      SelectProps={{
        displayEmpty: true,
        autoWidth: true,
        multiple: true,
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
        renderValue: RenderCategoryValue,
      }}
      value={cat}
      onChange={({target: {value}}) => setCat(value)}
    >
      {[
        'anonymizers',
        'dga',
        'malware',
        'phishing',
        'reputation',
      ].map((list) => (
        <MenuItem key={list} value={list}>
          <RenderCategory list={list}/>
        </MenuItem>
      ))}
    </RedTextField>
  );

  const title = <>
    <b style={{margin: 'auto 0'}}>Threats</b>
    {selection}
  </>;
  const theme = useTheme();
  const padding = theme.spacing(3);

  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      {!small && (
        <Grid
          item
          xs={12}
          style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
        >
          <Divider style={{marginTop: 104 + 90}}/>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Table
          columns={columns}
          title={title}
          url="threats"
          extradata={{
            t_r: timeRange,
            sort_column: 'last_seen',
            sort_direction: 'desc',
            client_name: selectedService,
            list: cat,
          }}
        />
        {data && <DetailRowDialog data={data} setOpen={setOpen}/>}
      </Grid>
    </Grid>
  );
}
Threats.propTypes = {
  path: PropTypes.string,
};

/**
 *
 * @param{{}} data
 * @param{function} setOpen
 * @return {JSX.Element}
 * @constructor
 */
function DetailRowDialog({data, setOpen}) {
  const action =
    data.last_action === 'PASSTHRU' ?
      data.last_action :
      data.last_action && 'blocked';
  const el = data.cat;
  return (
    <Dialog open={!!data}>
      <DialogTitle>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <RenderDomainLink domain={data.resolved_domain[0]}/>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableMui>
          <TableBody>
            {data.list && (
              <TableRow>
                <TableCell style={{textAlign: 'right'}}>
                  <b>List</b>
                </TableCell>
                <TableCell>
                  {data.list.map((l, i) => {
                    const categoryKey = 'category-' + i;
                    return <RenderCategory key={categoryKey} list={l}/>;
                  })}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Hits</b>
              </TableCell>
              <TableCell>{data.hits}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Classification</b>
              </TableCell>
              <TableCell>
                {(el &&
                    el.split(' ').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ')) ||
                  '-'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Action</b>
              </TableCell>
              <TableCell>
                <RenderAction action={action}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>First Seen</b>
              </TableCell>
              <TableCell>
                {moment(data.first_seen).format('DD-MM-YYYY HH:mm:ss')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Last Seen</b>
              </TableCell>
              <TableCell>
                {moment(data.last_seen).format('DD-MM-YYYY HH:mm:ss')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{textAlign: 'right'}}>
                <b>Service</b>
              </TableCell>
              <TableCell>
                <ServiceRender hash={data.clientname}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </TableMui>
      </DialogContent>
      <DialogActions style={{justifyContent: 'start'}}>
        <ButtonForm
          variant="outlined"
          type="button"
          onClick={(_) => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} style={{margin: 'auto 5px'}}/>
          Close
        </ButtonForm>
      </DialogActions>
    </Dialog>
  );
}

DetailRowDialog.propTypes = {
  data: PropTypes.object,
  setOpen: PropTypes.func,
};
