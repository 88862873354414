import React, {useEffect, useState} from 'react';
import {useTheme} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import EndpointBypassTable from '../service/EndpointBypassTable';
import {useGetDataBackend} from '../../../ApiBackend';
import {EndpointHostsTable} from './EndpointHostsTable';
import {ServiceHeader} from '../../../DNS8Components/Pages/ServiceHeader';
import {useSelector} from 'react-redux';
import BoxInfo from '../../../Components/BoxInfo';
import RedIconButton from '../../../core/components/buttons/RedIconButton';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EndpointAgentService from './EndpointAgentService';

/**
 *
 * @param{string} url
 * @return {Element}
 * @constructor
 */
export default function EndpointAgentSettings({url}) {
  let {
    data: {info},
    error,
    loading,
  } = useGetDataBackend('services/info/all');
  const [selectedService] = useSelector((state) => [
    state.selectedService,
  ]);
  if (info && !['all', ''].includes(selectedService)) {
    info = info.filter((i) => i.hash === selectedService);
  }

  const padding = useTheme().spacing(3);

  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  useEffect(() => {
    if (url) {
      window.history.replaceState(null, null, url);
    }
  }, [url]);

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        <Divider style={{marginTop: 104}}/>
      </Grid>

      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
      </Grid>
      <Grid item xs={12} style={{height: padding, margin: 'auto'}}/>
      {!(error || loading) &&
        info.map((inf) => {
          const clientHash = inf.client_hash;
          const fragKey = 'fragkey-' + clientHash;
          return (<React.Fragment key={fragKey}>
            <EndpointAgentService service={inf} />
          </React.Fragment>);
        })}
      <Grid
        item
        xs={12}
        style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
      >
        &nbsp;
      </Grid>
    </Grid>
  );
}

EndpointAgentSettings.propTypes = {
  url: PropTypes.string,
};


